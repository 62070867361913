import React, { Component } from 'react';
import './contacto.styles.css'
import { API_URL } from '../config'


class Contacto extends Component {

    state = {
        name: '',
        email: '',
        message: '',
        error: null,
        success: false
    }
    timeout = null;

    sendEmail = (e) => {
        e.preventDefault()

        const { name, email, message } = this.state

        if (name === null || email === null || message === null) {

            this.setState({ error: 'Debe rellenar todos los campos', success: false })
            clearTimeout(this.timeout);
            // Set a timeout to wait for the user to stop writing
            // So we don´t have to make unnessesary calls
            this.timeout = setTimeout(() => {
                this.setState({ error: null });
            }, 5000);
            return false
        }

        let formData = new FormData()
        formData.append('nombre', name)
        formData.append('email', email)
        formData.append('message', message)
        fetch(`${API_URL}message`, { method: 'POST', body: formData })
            .then(
                response => {
                    response.json()
                        .then(
                            res => {
                                if (res.res === "ok") {
                                    this.setState({ error: null, success: true, name: '', email: '', message: '' })

                                    clearTimeout(this.timeout);
                                    // Set a timeout to wait for the user to stop writing
                                    // So we don´t have to make unnessesary calls
                                    this.timeout = setTimeout(() => {
                                        this.setState({ success: false });
                                    }, 5000);
                                } else {
                                    this.setState({ error: "Ha ocurrido inesperado en el servidor", success: false })
                                    clearTimeout(this.timeout);
                                    // Set a timeout to wait for the user to stop writing
                                    // So we don´t have to make unnessesary calls
                                    this.timeout = setTimeout(() => {
                                        this.setState({ error: null });
                                    }, 5000);
                                }
                            }
                        )
                        .catch(err => console.log('err :>> ', err))
                }
            )
            .catch(err => console.log('err :>> ', err))

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        const { error, success } = this.state
        return (
            <div>
                <section className="u-align-center u-clearfix u-grey-10 u-section-1" id="carousel_0344">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="u-container-style u-expanded-width-sm u-expanded-width-xs u-group u-white u-group-1">
                            <div className="u-container-layout u-container-layout-1">
                                <div className="u-palette-5-base u-shape u-shape-rectangle u-shape-1"></div>
                            </div>
                        </div>
                        <img className="u-image u-image-default u-image-1" src="/images/reactMovie_logo.png" alt="" data-image-width="1696" data-image-height="790" />
                        <div className="u-clearfix u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-layout-wrap u-layout-wrap-1">
                            <div className="u-layout" >
                                <div className="u-layout-row" >
                                    <div className="u-container-style u-layout-cell u-size-20 u-layout-cell-1">
                                        <div className="u-container-layout u-container-layout-2">
                                            <h4 className="u-align-center u-text u-text-default u-text-1">Estamos en</h4>
                                            <p className="u-align-center u-text u-text-2">Calle 74 #4308 entre Calle 43A y 45, Marianao, La Habana.<br />
                                                <br />Si eres malo con las direcciones quizás te ayude saber que estamos por el lateral de Tropicana.<br />Te esperamos!!!
                                            </p>
                                        </div>
                                    </div>
                                    <div className="u-container-style u-layout-cell u-size-20 u-layout-cell-2">
                                        <div className="u-container-layout u-valign-top u-container-layout-3">
                                            <h4 className="u-text u-text-default u-text-3">Ví­as de contacto</h4>
                                            <p className="u-align-center u-text u-text-default u-text-4">dgiga@estudiosdgiga.com<br />
                                                <br />+53 7 261 4199<br />
                                                <br />+53 5 324 1857<br />
                                                <br />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="u-container-style u-layout-cell u-size-20 u-layout-cell-3">
                                        <div className="u-container-layout u-container-layout-4">
                                            <h4 className="u-align-center u-text u-text-default u-text-5">Nuestras redes</h4>
                                            <div className="u-social-icons u-spacing-10 u-social-icons-1">
                                                <a className="u-social-url" title="facebook" href="https://facebook.com/estudiosdgiga"><span className="u-icon u-social-facebook u-social-icon u-icon-1"><img alt='fb' height="64" src="/images/fb.png" /></span>
                                                </a>
                                                <a className="u-social-url" title="instagram" href="https://instagram.com/estudios_dgiga"><span className="u-icon u-social-icon u-social-instagram u-icon-2"><img alt='ig' height="64" src="/images/ig.png" /></span>
                                                </a>
                                                <a className="u-social-url" title="Whatsapp" href="https://chat.whatsapp.com/K7ROmk9nKyuDJZ11e4OpTC"><span className="u-icon u-social-icon u-social-whatsapp u-icon-3"><img alt='wp' height="64" src="/images/wp.png" /></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="u-align-center u-clearfix u-grey-10 u-section-2" id="sec-937d">

                    <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
                        <div className="u-form u-form-1">
                            <h2>Formulario de contacto</h2>
                            <form action="#" method="POST" className="u-clearfix u-form-spacing-15 u-form-vertical u-inner-form" style={{ padding: "15px" }} source="custom" name="form">
                                <div className="u-form-group u-form-name u-form-group-1">
                                    <label htmlFor="name-6797" className="u-form-control-hidden u-label">Name</label>
                                    <input onChange={this.onChange} value={this.state.name} type="text" placeholder="Nombre" id="name-6797" name="name" className="u-border-1 u-border-grey-30 u-input u-input-rectangle" required="" />
                                </div>
                                <div className="u-form-email u-form-group u-form-group-2">
                                    <label htmlFor="email-6797" className="u-form-control-hidden u-label">Email</label>
                                    <input onChange={this.onChange} value={this.state.email} type="email" placeholder="Email" id="email-6797" name="email" className="u-border-1 u-border-grey-30 u-input u-input-rectangle" required="" />
                                </div>
                                <div className="u-form-group u-form-message u-form-group-3">
                                    <label htmlFor="message-6797" className="u-form-control-hidden u-label">Address</label>
                                    <textarea onChange={this.onChange} value={this.state.message} placeholder="Escriba su comentario o sugerencia" rows="4" cols="50" id="message-6797" name="message" className="u-border-1 u-border-grey-30 u-input u-input-rectangle" required=""></textarea>
                                </div>
                                <div className="u-align-left u-form-group u-form-submit u-form-group-4">

                                    <input onClick={this.sendEmail} type="submit" value="Enviar" className="u-btn u-btn-submit u-button-style" />
                                </div>
                                <div>
                                    <div className={(success ? 'u-form-send-success' : '') + " u-form-send-message"}>
                                        {
                                            success ? 'Gracias por contactarnos, en breve responderemos.' : ''
                                        }
                                    </div>
                                    <div className={(error ? 'u-form-send-error' : '') + ' u-form-send-message'}>
                                        {
                                            error ? error : ""
                                        }
                                    </div>
                                </div>
                                <input type="hidden" value="" name="recaptchaResponse" />
                            </form>
                        </div>
                    </div>
                </section>

            </div>
        )
    }

}



export default Contacto;