import React from 'react';


const Footer = () => (
  <div>
    {
      //arreglar estilos y poner boton para suscribirse, mandar el email para la tabla de usuarios, el username y la contraseña seran el email
    }
    
    <div style={{ color: "#fff", textAlign: 'center', marginTop: '20px', backgroundColor: '#333', padding: '10px' }}>
      <p>Estudios DGIGA &copy; 2018-{new Date().getFullYear()} </p>
    </div>
  </div>

)

export default Footer;