import React from 'react';
import './temporadas.styles.css';

const Temporadas = ({ temporadas }) => (
    <div>
        <h2>Temporadas</h2>
        <div className="table-responsive">
            <table style={{width:"100%", textAlign:'center'}}>
                <thead>
                    <th>Temporada</th>
                    <th>Capítulos</th>
                </thead>
                <tbody>
                    {temporadas.map(el =>
                        <tr><td>{el.temporada}</td><td>{el.capitulos}</td></tr>
                    )}
                </tbody>
            </table>
        </div>
    </div>
)

export default Temporadas