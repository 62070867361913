import React, { Component } from 'react';
import { API_URL, IMAGE_BASE_URL } from '../../config';
import HeroImage from '../HeroImage/HeroImage.component';
import SearchBar from '../SearchBar/SearchBar.component';
import FourColGrid from '../FourColGrid/FourColGrid.component';
import MovieThumb from '../MovieThumb/MovieThumb.component';
import LoadMoreBtn from '../LoadMoreBtn/LoadMoreBtn.component';
import Spinner from '../Spinner/Spinner.component';
import '../list.styles.css';

class NovelasAll extends Component {
    state = {
        movies: [],
        heroImage: null,
        loading: false,
        currentPage: 0,
        totalPages: 0,
        searchTerm: ''
    }

    componentDidMount() {

        this.setState({ loading: true })
        const endpoint = `${API_URL}novelas?page=1`;
        this.fetchItems(endpoint);

    }

    searchItems = (searchTerm) => {
        let endpoint = '';
        this.setState({
            movies: [],
            loading: true,
            searchTerm
        })

        if (searchTerm === "") {
            endpoint = `${API_URL}novelas?page=1`;
        } else {
            endpoint = `${API_URL}novelas?s=${searchTerm}`;
        }
        this.fetchItems(endpoint);
    }

    loadMoreItems = () => {
        const { searchTerm, currentPage } = this.state;

        let endpoint = '';
        this.setState({ loading: true })

        if (searchTerm === '') {
            endpoint = `${API_URL}novelas?page=${currentPage + 1}`;
        } else {
            endpoint = `${API_URL}novelas?s=${searchTerm}&page=${currentPage + 1}`;
        }
        this.fetchItems(endpoint);
    }

    fetchItems = (endpoint) => {
        const { movies, heroImage, searchTerm } = this.state;

        fetch(endpoint)
            .then(result => result.json())
            .then(result => {
                this.setState({
                    movies: [...movies, ...result.novelas],
                    heroImage: heroImage || result.novelas[0],
                    loading: false,
                    currentPage: result.page,
                    totalPages: result.total_pages
                }, () => {
                    // Remember state for the next mount if weÂ´re not in a search view
                    if (searchTerm === "") {
                        sessionStorage.setItem('HomeState', JSON.stringify(this.state));
                    }
                })
            })
            .catch(error => console.error('Error:', error))
    }

    render() {

        const { movies, heroImage, loading, currentPage, totalPages, searchTerm } = this.state;
        
        return (
            <div className="rmdb-home">

                <div>
                    {heroImage ?
                        <HeroImage
                            image={`${IMAGE_BASE_URL}fondos/novelas.png`}
                            title={heroImage.original_title}
                        /> : null}
                    < SearchBar callback={this.searchItems} />
                </div>
                <div className="rmdb-home-grid">
                    <FourColGrid
                        header={searchTerm ? 'Resultados' : `Mostrando ${currentPage*12} de ${totalPages*12}`}
                        loading={loading}
                    >
                        {movies.map((element, i) => (
                            <MovieThumb
                                key={i}
                                clickable={true}
                                image={element.photo ? `${IMAGE_BASE_URL}novelas/${element.photo}` : './images/no_image.jpg'}
                                movieId={element.id}
                                movieName={element.nombre}
                                mediaType="novelas"
                            />
                        ))}
                    </FourColGrid>
                    {loading ? <Spinner /> : null}
                    {(currentPage <= totalPages && !loading) ?
                        <LoadMoreBtn text="Cargar más" onClick={this.loadMoreItems} />
                        : null
                    }
                </div>
            </div>
        )
    }
}

export default NovelasAll;