import React, { Component } from 'react';
import { API_URL } from '../../config';
import Navigation from '../Navigation/Navigation.component';
import MovieInfo from '../MovieInfo/MovieInfo.component';
import Spinner from '../Spinner/Spinner.component';
import './series.styles.css';

class Series extends Component {
  state = {
    movie: null,
    loading: false
  }

  componentDidMount() {
    const { serieId } = this.props.match.params;

    
      this.setState({ loading: true })
      // First fetch the movie ...
      let endpoint = `${API_URL}series/${serieId}`;
      this.fetchItems(endpoint);

    
  }

  fetchItems = (endpoint) => {
    
    fetch(endpoint)
    .then(result => result.json())
    .then(result => {

      if (result.status_code) {
        // If we don't find any movie
        this.setState({ loading: false });
      } else {
        this.setState({ movie: result, loading: false })
      }
    })
    .catch(error => console.error('Error:', error))
  }

  render() {
    const { movie, loading } = this.state;

    return (
      <div className="rmdb-movie">
        {movie ?
        <div>
          <Navigation tipo="Series" movie={movie.nombre} />
          <MovieInfo tipo="series" movie={movie} />
        </div>
        : null }
        
        {loading ? <Spinner /> : null}
      </div>
    )
  }
}

export default Series;