import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div style={{ color: "#fff", textAlign: 'center', marginTop: '20px' }}>
    <h1>Parece que te has perdido!!!</h1>
    <h3>Esta página no existe. Haz click <Link to="/">aquí</Link> para ir al inicio.</h3>
  </div>
)

export default NotFound;