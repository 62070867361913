import React, { Component } from 'react';
import { API_URL, IMAGE_BASE_URL } from '../config';
import HeroImage from './HeroImage/HeroImage.component';
import FourColGrid from './FourColGrid/FourColGrid.component';
import MovieThumb from './MovieThumb/MovieThumb.component';
import Spinner from './Spinner/Spinner.component';
import LoadMoreBtn from './LoadMoreBtn/LoadMoreBtn.component';


import './Home/Home.styles.css';
import './buscar.styles.css';

class Buscar extends Component {
    state = {
        loaded: false,
        resultados: [],
        paises: [],
        generos: [],
        loading: false,
        currentPage: 0,
        totalPages: 0,
        findNombreInput: "",
        findGeneroInput: "",
        findYearInput: "",
        findPaisInput: "",
        findTipoInput: ""
    }

    componentDidMount() {
        this.fetchPaises()
        this.fetchGeneros()
    }

    fetchPaises = () => {
        fetch(`${API_URL}paises`)
            .then(result => result.json())
            .then(result => {
                this.setState({
                    paises: result.paises
                })
            })
            .catch(error => console.error('Error:', error))
    }

    fetchGeneros = () => {
        fetch(`${API_URL}generos`)
            .then(result => result.json())
            .then(result => {
                this.setState({
                    generos: result.generos
                })
            })
            .catch(error => console.error('Error:', error))
    }

    fetchItems = (endpoint, data) => {
        const { resultados } = this.state

        fetch(endpoint, { method: "POST", body: data })
            .then(result => result.json())
            .then(result => {
                this.setState({
                    loaded: true,
                    resultados: [...resultados, ...result.data],
                    //heroImage: heroImage || result.results[0],
                    loading: false,
                    currentPage: result.page,
                    totalPages: result.total_pages
                })

            })
            .catch(error => console.error('Error:', error))
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    loadMoreItems = () => {
        const { currentPage } = this.state;


        this.setState({ loading: true })

        let xx = this.getDataForm()

        xx.append("page", currentPage + 1)

        this.fetchItems(`${API_URL}search`, xx)
    }

    getDataForm = () => {
        let xx = new FormData()

        if (this.state.findNombreInput !== "") {
            xx.append("nombre", this.state.findNombreInput)
        }
        if (this.state.findGeneroInput !== "") {
            xx.append("genero", this.state.findGeneroInput)
        }
        if (this.state.findYearInput !== "") {
            xx.append("year", this.state.findYearInput)
        }
        if (this.state.findPaisInput !== "") {
            xx.append("pais", this.state.findPaisInput)
        }
        xx.append("tipo", this.state.findTipoInput)

        return xx
    }

    submitForm = async (e) => {
        e.preventDefault();
        await this.setState({ loading: true, resultados: [], currentPage: 0, totalPages:0 })

        let xx = this.getDataForm()

        this.fetchItems(`${API_URL}search`, xx)
    }

    render() {

        const { resultados, loading, paises, generos, currentPage, totalPages, loaded } = this.state;

        return (
            <div className="rmdb-home" style={{
                background: `linear-gradient(rgba(255,255,255,.7), rgba(255,255,255,.5)),url(${IMAGE_BASE_URL}portada.png) repeat`
            }}>

                <div>
                    <HeroImage
                        image={`${IMAGE_BASE_URL}portada.png`}

                    />

                    {//<SearchBar callback={this.searchItems} />
                    }
                </div>

                <div className="containerSearch">
                    <div className="searchForm">
                        <form className="form-search">
                            <div className="form-group">
                                <label htmlFor="findNombreInput">Nombre</label>
                                <input onChange={this.onChange} type="text" className="form-control" id="findNombreInput" placeholder="Buscar por nombre" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="findGeneroInput">Género</label>
                                <select onChange={this.onChange} id="findGeneroInput" className="form-control">
                                    <option value="">Seleccione...</option>
                                    {
                                        generos.map(el => (
                                            <option value={el}>{el}</option>
                                        )
                                        )
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="findYearInput">Año</label>
                                <input onChange={this.onChange} type="number" min="1900" max={new Date().getFullYear()} className="form-control" id="findYearInput" placeholder="Año" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="findPaisInput">País</label>
                                <select onChange={this.onChange} id="findPaisInput" className="form-control">
                                    <option value="">Seleccione...</option>
                                    {
                                        paises.map(el => (
                                            <option value={el}>{el}</option>
                                        )
                                        )
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="findTipoInput">Tipo</label>
                                <select onChange={this.onChange} id="findTipoInput" className="form-control">
                                    <option value="">Seleccione...</option>
                                    <option value="animes">Anime</option>
                                    <option value="filmes">Filme</option>
                                    <option value="novelas">Novela</option>
                                    <option value="realitys">Reality</option>
                                    <option value="series">Serie</option>
                                </select>
                            </div>

                            <button onClick={this.submitForm} type="submit" className="btn btn-primary">Buscar</button>
                        </form>
                    </div>
                </div>
                {loaded ?
                    <div className="containerSearch">

                        <div className="rmdb-home-grid">

                            <FourColGrid
                                header={'Resultados de la búsqueda'}
                                loading={loading}
                            >
                                {resultados ?
                                    resultados.map((element, i) => (
                                        <MovieThumb
                                            key={i}
                                            clickable={true}
                                            image={element.photo ? `${IMAGE_BASE_URL}${element.tipo}/${element.photo}` : './images/no_image.jpg'}
                                            movieId={element.id}
                                            movieName={element.nombre}
                                            mediaType={element.tipo}
                                        />
                                    )) : ""
                                }
                            </FourColGrid>

                            {loading ? <Spinner /> : null}
                            {(currentPage <= totalPages && !loading) ?
                                <LoadMoreBtn text="Cargar más" onClick={this.loadMoreItems} />
                                : null
                            }
                        </div>

                    </div>
                    : ""}
            </div>
        )
    }
}

export default Buscar;