import React, { Component } from 'react';
import { API_URL } from '../../config';
import Navigation from '../Navigation/Navigation.component';
import MovieInfo from '../MovieInfo/MovieInfo.component';
import Spinner from '../Spinner/Spinner.component';
import './novelas.styles.css';

class Novelas extends Component {
  state = {
    movie: null,
    loading: false
  }

  componentDidMount() {
    const { novelaId } = this.props.match.params;


    this.setState({ loading: true })
    // First fetch the movie ...
    let endpoint = `${API_URL}novelas/${novelaId}`;
    this.fetchItems(endpoint);


  }

  fetchItems = (endpoint) => {
    
    fetch(endpoint)
      .then(result => result.json())
      .then(result => {

        if (result.status_code) {
          // If we don't find any movie
          this.setState({ loading: false });
        } else {
          this.setState({ movie: result, loading: false })
        }
      })
      .catch(error => console.error('Error:', error))
  }

  render() {
    const { movieName } = this.props.location;
    const { movie, loading } = this.state;
    
    return (
      <div className="rmdb-movie">
        {movie ?
          <div>
            <Navigation tipo="Novelas" movie={movieName} />
            <MovieInfo tipo="novelas" movie={movie} />
          </div>
          : null}

        {loading ? <Spinner /> : null}
      </div>
    )
  }
}

export default Novelas;