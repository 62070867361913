import React from 'react';
import Menus from '../Menu/menu.component';
import './Header.styles.css';

const Header = () => (
  <div>
    <Menus />
    
  </div>

)

export default Header;