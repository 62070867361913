import React, { Component } from 'react';
import { IMAGE_BASE_URL, API_URL } from '../../config';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import MovieThumb from '../MovieThumb/MovieThumb.component';
import './MovieInfo.styles.css';
import Temporadas from '../Temporadas/temporadas.component';
import Rate from 'rc-rate';
import { Helmet } from "react-helmet";
import 'rc-rate/assets/index.css';


class MovieInfo extends Component {

    constructor(props) {
        super(props);
        // No llames this.setState() aquí!
        this.state = {
            movie: props.movie,
            tipo: props.tipo,
            rate: 4.5
        };

        this.getRate = this.getRate.bind(this)
        this.setRate = this.setRate.bind(this)
    }

    componentDidMount() {
        this.getRate()
    }

    getRate = () => {
        const { movie, tipo } = this.state
        fetch(`${API_URL}rates/${tipo}/${movie.id}`)
            .then(res => {
                res.json()
                    .then(response => {
                        if (response.response === true) {
                            this.setState({ rate: response.rated })
                        }
                    })
                    .catch(err => console.log(err))
            })
            .catch(err => console.log(`err`, err))
    }

    setRate = (rated) => {
        const { movie, tipo } = this.state


        fetch(`${API_URL}rate/add/${tipo}/${movie.id}/${rated}`)
            .then(
                response => {
                    response.json()
                        .then(res => {
                            this.getRate()
                        })
                        .catch(err => console.log(`err`, err))
                }
            )
            .catch(err => console.log(`err`, err))
    }

    render() {
        const { movie, tipo, rate } = this.state

        return (
            <div className="rmdb-movieinfo"
                style={{
                    background: `linear-gradient(rgba(255,255,255,.7), rgba(255,255,255,.5)), url('${IMAGE_BASE_URL}fondos/${tipo}.png') repeat-y`
                }}
            >
                <Helmet>
                <title>{movie.nombre} - Estudios DGIGA</title>
                    <meta name="description" content={(movie.sinopsis).substr(0, (movie.sinopsis).length > 75 ? 75 : (movie.sinopsis).length) + "... sigue leyendo en nuestra web"} />
                    <meta property="og:description" content={(movie.sinopsis).substr(0, (movie.sinopsis).length > 75 ? 75 : (movie.sinopsis).length) + "... sigue leyendo en nuestra web"} />
                    <meta property="og:title" content={(movie.nombre).substr(0, (movie.nombre).length > 30 ? 30 : movie.length)} />
                    <meta property="og:image" content={`${IMAGE_BASE_URL}${tipo}/${movie.photo}`} />
                    <meta property="og:url" content={`${API_URL}${tipo}/${movie.id}`} />
                    <link rel="canonical" href={`${API_URL}${tipo}/${movie.id}`} />
                </Helmet>
                
                <div className="rmdb-movieinfo-content">
                    <div className="rmdb-movieinfo-thumb">
                        <MovieThumb
                            image={`${IMAGE_BASE_URL}${tipo}/${movie.photo}`}
                            clickable={false}
                        />
                    </div>
                    <div className="rmdb-movieinfo-text">
                        <h1>{movie.nombre}</h1>
                        <div><Rate onChange={this.setRate} value={rate} allowHalf={true} style={{ fontSize: 24 }} /></div>
                        <h3>{movie.year ? `Año: ${movie.year}` : ''}</h3>
                        <h3>{movie.genero ? `Géneros: ${movie.genero}` : ''}</h3>
                        <h3>{movie.pais ? `País: ${movie.pais}` : ''}</h3>
                        <h3>{movie.capitulos ? `Capítulos: ${movie.capitulos}` : ''}</h3>
                        <h3>{movie.estado ? `Estado: ${movie.estado}` : ''}</h3>
                        <h3>{movie.formato ? `Formato: ${movie.formato}` : ''}</h3>
                        <h3>{movie.subtitulada ? `Subtitulada` : ''}</h3>

                        <h3>Sinopsis:</h3>
                        <p>{movie.sinopsis}</p>

                        {movie.temporadas && (movie.temporadas).length !== 0 ? <Temporadas temporadas={movie.temporadas} /> : ''}

                    </div>
                    <FontAwesome className="fa-film" name="film" size="5x" />
                </div>
            </div>
        )
    }
}



MovieInfo.propTypes = {
    movie: PropTypes.object,
    tipo: PropTypes.string
}

export default MovieInfo;