import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import './menu.styles.css'

class Menus extends Component {

    myFunc = () => {
        let x = document.getElementById('myTopnav')
        if (x.className === 'topnav') {
            x.className += " responsive";
        } else {
            x.className = "topnav";
        }
    }

    delResponsive = () => {
        let x = document.getElementById('myTopnav')
        if (x.className !== 'topnav') {
            x.className = "topnav";
        }
    }

    render() {
        return (
            <div className="topnav" id="myTopnav">
                <div onClick={this.delResponsive}>
                    <Link to="/">Inicio</Link>
                    <Link to="/series">Series</Link>
                    <Link to="/novelas">Novelas</Link>
                    <Link to="/filmes">Filmes</Link>
                    <Link to="/animes">Animes</Link>
                    <Link to="/realitys">Realitys</Link>
                    <Link to="/contacto">Contacto</Link>
                    <Link to="/buscar">Buscar</Link>
                    
                </div>
                <Link className="icon" onClick={this.myFunc} to="#"><FontAwesomeIcon icon={faList} /></Link>
            </div>
        )
    }

}


export default Menus