import React, { Component } from 'react';
import { API_URL, IMAGE_BASE_URL } from '../../config';
import HeroImage from '../HeroImage/HeroImage.component';
import FourColGrid from '../FourColGrid/FourColGrid.component';
import MovieThumb from '../MovieThumb/MovieThumb.component';
import Spinner from '../Spinner/Spinner.component';

import './Home.styles.css';

class Home extends Component {
  state = {
    movies: [],
    loading: false
  }

  componentDidMount() {
    this.setState({ loading: true })
    const endpoint = `${API_URL}home`;
    this.fetchItems(endpoint);
  }

  fetchItems = (endpoint) => {

    fetch('https://api.themoviedb.org/3/movie/popular?api_key=687f4af88c405c0b881295bba3d4adfc&language=en-US&page=1')
      .then(res => res.json()
        .then(response => {
          this.setState({ heroImage: response.results[0] })
        })
      )

    fetch(endpoint)
      .then(result => result.json())
      .then(result => {
        this.setState({
          movies: result.data,
          //heroImage: heroImage || result.results[0],
          loading: false
        })

      })
      .catch(error => console.error('Error:', error))
  }

  render() {

    const { movies, loading } = this.state;

    return (
      <div className="rmdb-home">
        
          <div>
            <HeroImage
              image={`${IMAGE_BASE_URL}portada.png`}
              
            />
            
            {//<SearchBar callback={this.searchItems} />
            }
          </div> 
          
        <div className="rmdb-home-grid">
          <FourColGrid
            header={'+Popular'}
            loading={loading}
          >
            {movies.map((element, i) => (
              <MovieThumb
                key={i}
                clickable={true}
                image={element.photo ? `${IMAGE_BASE_URL}${element.tipo}/${element.photo}` : './images/no_image.jpg'}
                movieId={element.id}
                movieName={element.nombre}
                mediaType={element.tipo}
              />
            ))}
          </FourColGrid>
          {loading ? <Spinner /> : null}
        </div>
      </div>
    )
  }
}

export default Home;