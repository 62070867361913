import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Animes from './components/Animes/animes.component';
import AnimesAll from './components/Animes/animesAll.component';
import Filmes from './components/Filmes/filmes.component';
import FilmesAll from './components/Filmes/filmesAll.component';
import Header from './components/Header/header.component';
import Home from './components/Home/home.component';
import Novelas from './components/Novelas/novelas.component';
import NovelasAll from './components/Novelas/novelasAll.component';
import Realitys from './components/Realitys/realitys.component';
import RealitysAll from './components/Realitys/realitysAll.component';
import Series from './components/Series/series.component'
import SeriesAll from './components/Series/seriesAll.component';

//import Movie from '../Movie/Movie.component';
import NotFound from './components/NotFound/NotFound.component';
import Footer from './components/Footer/footer.component';
import Contacto from './components/contacto.component';
import Buscar from './components/buscar.component';



class App extends Component {

  componentDidMount() {

  }


  render() {

    return (
      <BrowserRouter>
        <React.Fragment>
          <Header />

          
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/series" component={SeriesAll} exact />
            <Route path="/novelas" component={NovelasAll} exact />
            <Route path="/realitys" component={RealitysAll} exact />
            <Route path="/filmes" component={FilmesAll} exact />
            <Route path="/animes" component={AnimesAll} exact />
            <Route path="/contacto" component={Contacto} exact />
            <Route path="/buscar" component={Buscar} exact />
            <Route path="/series/:serieId" component={Series} exact />
            <Route path="/novelas/:novelaId" component={Novelas} exact />
            <Route path="/realitys/:realityId" component={Realitys} exact />
            <Route path="/filmes/:filmeId" component={Filmes} exact />
            <Route path="/animes/:animeId" component={Animes} exact />


            <Route component={NotFound} />
          </Switch>
          <Footer />
          
        </React.Fragment>
      </BrowserRouter>
    )
  }
}

export default App;